<template>
  <section class="mb-16 pb-16">
    <div
      class="
        header-container header-restaurante
        d-flex
        justify-center
        align-center
      "
    >
      <h1 class="text-md-h1 text-h3 header-text">RESTAURANTE</h1>
    </div>

    <section class="conteudo-page mb-16">
      <v-row class="item-pagina ml-0 ml-md-n4">
        <v-col
          class="
            section-imgs
            item-foto
            col-sm-12 col-md-6 col-lg-5
            d-flex
            flex-column
          "
          style="min-width: 40%"
        >
        
          <v-carousel
            v-model="modelR"
            cycle
            hide-delimiter-background
            show-arrows-on-hover
            delimiter-icon="mdi-minus"
            height="600"
          >
            <template v-slot:prev="{ on, attrs }">
              <div class="primary pa-3 arrow-slider" v-bind="attrs" v-on="on" 
              >
                  <v-icon>mdi-arrow-left</v-icon>
                </div>
            </template>
            <template v-slot:next="{ on, attrs }">
              <div class="primary pa-3 arrow-slider arrow-right" v-bind="attrs" v-on="on"              >
                  <v-icon>mdi-arrow-right</v-icon>
                </div>
            </template>
  
            <v-carousel-item class="imgs mb-16">
                  <v-img src="../assets/restaurante/res-1.jpg" position="5% 50%" class="imgs mb-16"/>
            </v-carousel-item>
  
            <v-carousel-item class="imgs mb-16">
                  <v-img src="../assets/restaurante/res-2.jpg" position="55% 50%" class="imgs mb-16"/>
            </v-carousel-item>
  
            <v-carousel-item class="imgs mb-16">
                  <v-img src="../assets/restaurante/res-3.jpg"  class="imgs mb-16"/>
            </v-carousel-item>
  
            <v-carousel-item class="imgs mb-16">
                  <v-img src="../assets/restaurante/res-4.jpg"  class="imgs mb-16"/>
            </v-carousel-item>
  
            <v-carousel-item class="imgs mb-16">
                  <v-img src="../assets/restaurante/res-5.jpg"  class="imgs mb-16"/>
            </v-carousel-item>
          </v-carousel>
        </v-col>

        <v-col
          class="col-sm-12 col-md-6 col-lg-7 item-info pa-12 d-flex flex-column align-center justify-center"
        >
          <div class="d-flex flex-column align-center">
            <v-img
              class="preapend-header"
              src="../assets/tipografia.png"
            ></v-img>

            <h2
              class="
                item-header
                section-header
                text-lg-h2 text-md-h3 text-h3 text-center
                primary--text
              "
            >
              Restaurante
            </h2>
          </div>

          <p class="item-desc p-1 primary--text">
            Através das mãos dedicadas das nossas copeiras, cada prato, simples,
            mas feito com muito carinho se torna único. Nosso hortifrúti vem
            direto da feira da cidade, que é abastecida pelos agricultores da
            nossa região.
          </p>
          <v-btn class="item-cta mt-4" color="primary" dark x-large tile @click="abrirWhats()"
            >FAÇA SUA RESERVA</v-btn
          >
        </v-col>
      </v-row>

      <v-row class="item-pagina ml-0">
        <v-col
          class="item-info pa-12 d-flex flex-column align-center justify-center"
        >
          <div class="d-flex flex-column align-center">
            <h2
              class="
                item-header
                section-header
                text-md-h2 text-h3 text-center
                primary--text
              "
            >
              Bar
            </h2>
          </div>

          <p class="item-desc p-1 primary--text">
            Após longa jornada de trabalho nossos drinks são servidos para bom
            descanso de nossos hóspedes
          </p>
          <v-btn class="item-cta mt-4" color="primary" dark x-large tile @click="abrirWhats()"
            >FAÇA SUA RESERVA</v-btn
          >
        </v-col>

        <v-col
          class="
            section-imgs
            item-foto
            foto-right
            col-sm-12 col-md-6 col-lg-5
            d-flex
            flex-column
            mr-md-n4
          "
          style="min-width: 40%"
        >
          <v-carousel
            v-model="model"
            cycle
            hide-delimiter-background
            show-arrows-on-hover
            delimiter-icon="mdi-minus"
            height="600"
          >
            <template v-slot:prev="{ on, attrs }">
              <div class="primary pa-3 arrow-slider" v-bind="attrs" v-on="on" 
              >
                  <v-icon>mdi-arrow-left</v-icon>
                </div>
            </template>
            <template v-slot:next="{ on, attrs }">
              <div class="primary pa-3 arrow-slider arrow-right" v-bind="attrs" v-on="on"              >
                  <v-icon>mdi-arrow-right</v-icon>
                </div>
            </template>
  
            <v-carousel-item class="imgs mb-16">
                  <v-img src="../assets/bar/bar-1.jpg" position="5% 50%" class="imgs mb-16"/>
            </v-carousel-item>
  
            <v-carousel-item class="imgs mb-16">
                  <v-img src="../assets/bar/bar-2.jpg" position="55% 50%" class="imgs mb-16"/>
            </v-carousel-item>
  
            <v-carousel-item class="imgs mb-16">
                  <v-img src="../assets/bar/bar-3.jpg"  class="imgs mb-16"/>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </section>
  </section>
</template>

<style lang="scss">
</style>

<script>
export default {
  name: "Restaurante",
    metaInfo: {
      title: 'Restaurante',
      titleTemplate: `%s | Roda d'Água`,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          property: 'og:title',
          content: "Restaurante",
          template: `%s | Roda d'Água`,
          vmid: 'og:title'
        }
      ],
    link: [
      {rel: 'canonical', href: 'https://www.hotelrodadagua.com.br/restaurante'}
    ]
    },
  data: () => {
    return {
      modelR: 0,
      model: 0
    }
  },
  created(){
    this.$emit('carregado')
  },
  methods:{
    
    abrirWhats(){
      window.open("https://wa.me/5518996992228");
    },
  }
};
</script>
